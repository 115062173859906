import CardField from "@/components/CardField";
import HostHelpSection from "@/components/HostHelpSection";
import { BodyContainer } from "@/components/Layout";
import { InlineSpinner } from "@/components/Spinner";
import { useQueryOptionsFactory } from "@/queries";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { dateFormatter } from "@/utils/format.ts";
import { Alert, Grid2, Link, Paper, Stack, Typography } from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ReactNode } from "react";
import ActionsBar from "./-components/ActionsBar.tsx";
import HostedFtpAccessCard from "./-components/HostedFtpAccessCard.tsx";
import WebDirectCustomHomeUrlsCard from "./-components/WebDirectCustomHomeUrlsCard.tsx";

const Root = (): ReactNode => {
    const { id: clientId } = useActiveClient();
    const params = Route.useParams();
    const qof = useQueryOptionsFactory();
    const { data: server } = useSuspenseQuery(qof.fileMakerServer.get(clientId, params.hostId));

    return (
        <>
            <ActionsBar server={server} sx={{ mb: 2 }} />
            {server.upgrade && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    <Typography variant="body2" gutterBottom>
                        We are preparing an upgrade to version {server.upgrade.approvedVersion}.
                        {server.upgrade.plannedOn &&
                            ` The planned date for the upgrade is ${server.upgrade.plannedOn.format(dateFormatter)}.`}
                    </Typography>
                    <Typography variant="body2">{server.upgrade.message}</Typography>
                </Alert>
            )}
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Paper sx={{ p: 2, height: "100%" }}>
                        <Stack
                            sx={{
                                gap: 2,
                            }}
                        >
                            <CardField
                                label="Admin console"
                                value={
                                    <Link
                                        href={server.adminConsoleUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {server.adminConsoleUrl}
                                    </Link>
                                }
                            />
                            <CardField label="Server version" value={server.version} />
                        </Stack>
                    </Paper>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <Paper sx={{ p: 2, height: "100%" }}>
                        <Stack
                            sx={{
                                gap: 2,
                            }}
                        >
                            <CardField label="License code" value={server.license.code} />
                            <CardField
                                label="Expiration date"
                                value={server.license.expiresOn.format(dateFormatter)}
                            />
                            <Typography>
                                <Link href={server.license.url} target="_blank" rel="noreferrer">
                                    Review your current licensing with Claris (FileMaker). Download
                                    licensing certificate and software.
                                </Link>
                            </Typography>
                        </Stack>
                    </Paper>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <HostedFtpAccessCard server={server} sx={{ height: "100%" }} />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                    <WebDirectCustomHomeUrlsCard server={server} sx={{ height: "100%" }} />
                </Grid2>
            </Grid2>

            <HostHelpSection
                supportResources={[{ label: "Managing FileMaker Server", type: "fms" }]}
            />
        </>
    );
};
export const Route = createFileRoute("/$clientId/hosts/$hostId/fms/")({
    component: Root,
    pendingComponent: () => (
        <BodyContainer>
            <InlineSpinner />
        </BodyContainer>
    ),
    loader: async ({ context, params }) => {
        await context.queryClient.ensureQueryData(
            context.qof.fileMakerServer.get(params.clientId, params.hostId),
        );
    },
});
