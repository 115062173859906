import { type SomeZodObject, z } from "zod";
import { generateRootSchema } from "./validation.ts";

const commonValueFieldSchema = z.object({
    type: z.enum(["string", "boolean"]),
    title: z.string(),
    description: z.string().optional(),
    minLength: z.number().optional(),
    maxLength: z.number().optional(),
    pattern: z.string().optional(),
    format: z.enum(["hostname", "uri", "email"]).optional(),
    maskValue: z.boolean().optional(),
    multiLine: z.boolean().optional(),
    code: z.boolean().optional(),
    errorMessages: z
        .object({
            pattern: z.string().optional(),
        })
        .optional(),
    displayGroup: z.string().optional(),
});

export type CommonValueField = z.output<typeof commonValueFieldSchema>;

const configValueFieldSchema = commonValueFieldSchema.extend({
    disableTrim: z.boolean().optional(),
    placeholderFromOutputs: z.string().optional(),
});

export type ConfigValueField = z.output<typeof configValueFieldSchema>;

const outputsValueFieldSchema = commonValueFieldSchema;

export type OutputsValueField = z.output<typeof outputsValueFieldSchema>;

const configObjectFieldSchema = z.object({
    type: z.literal("object"),
    title: z.string(),
    properties: z.record(configValueFieldSchema),
    required: z.array(z.string()).optional(),
    order: z.array(z.string()).optional(),
});

export type ConfigObjectField = z.output<typeof configObjectFieldSchema>;

const outputsObjectFieldSchema = z.object({
    type: z.literal("object"),
    title: z.string(),
    properties: z.record(outputsValueFieldSchema),
    required: z.array(z.string()).optional(),
    order: z.array(z.string()).optional(),
});

export type OutputsObjectField = z.output<typeof outputsObjectFieldSchema>;

export const sideCarManifestSchema = z.object({
    sideCarId: z.string(),
    majorVersion: z.number(),
    config: z.object({
        type: z.literal("object"),
        properties: z.record(
            z.discriminatedUnion("type", [configObjectFieldSchema, configValueFieldSchema]),
        ),
        required: z.array(z.string()).optional(),
        order: z.array(z.string()).optional(),
    }),
    outputs: z.object({
        type: z.literal("object"),
        properties: z.record(
            z.discriminatedUnion("type", [outputsObjectFieldSchema, outputsValueFieldSchema]),
        ),
        required: z.array(z.string()).optional(),
        order: z.array(z.string()).optional(),
    }),
});

export type SideCarManifest = z.output<typeof sideCarManifestSchema>;

const configSchemaCache = new Map<string, SomeZodObject>();
const outputsSchemaCache = new Map<string, SomeZodObject>();

const getSchema = (
    manifest: SideCarManifest,
    cache: Map<string, SomeZodObject>,
    source: "config" | "outputs",
): SomeZodObject => {
    const compoundId = `${manifest.sideCarId}-v${manifest.majorVersion}`;
    const cachedSchema = cache.get(compoundId);

    if (cachedSchema) {
        return cachedSchema;
    }

    const schema = generateRootSchema(manifest[source]);
    cache.set(compoundId, schema);

    return schema;
};

export const getConfigSchema = (manifest: SideCarManifest): SomeZodObject =>
    getSchema(manifest, configSchemaCache, "config");
export const getOutputsSchema = (manifest: SideCarManifest): SomeZodObject =>
    getSchema(manifest, outputsSchemaCache, "outputs");
