import useDialogController from "@/hooks/useDialogController.tsx";
import type { FileMakerServer } from "@/queries/filemaker-server.ts";
import EditIcon from "@mui/icons-material/Edit";
import {
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Link,
    type SxProps,
    Typography,
} from "@mui/material";
import type { ReactNode } from "react";
import { createPortal } from "react-dom";
import ChangeWebDirectCustomHomeUrlsDialog from "./ChangeWebDirectCustomHomeUrlsDialog.tsx";

type Props = {
    server: FileMakerServer;
    sx?: SxProps;
};

const WebDirectCustomHomeUrlsCard = ({ server, sx }: Props): ReactNode => {
    const dialogController = useDialogController();

    return (
        <>
            <Card sx={sx}>
                <CardHeader
                    title="WebDirect custom home URLs"
                    slotProps={{
                        title: { variant: "body1" },
                    }}
                    action={
                        <IconButton onClick={dialogController.open}>
                            <EditIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Typography
                        variant="body2"
                        sx={{
                            mb: 2,
                        }}
                    >
                        {server.webDirectCustomHomeUrls.length > 0 ? (
                            <>
                                {server.webDirectCustomHomeUrls.map((url) => (
                                    <div key={url}>
                                        <Link href={url} target="_blank" rel="noreferrer">
                                            {url}
                                        </Link>
                                    </div>
                                ))}
                            </>
                        ) : (
                            "You have not configured any custom home URLs."
                        )}
                    </Typography>
                </CardContent>
            </Card>
            {dialogController.mount &&
                createPortal(
                    <ChangeWebDirectCustomHomeUrlsDialog
                        hostId={server.hostId}
                        urls={server.webDirectCustomHomeUrls}
                        dialogProps={dialogController.dialogProps}
                    />,
                    document.body,
                )}
        </>
    );
};
export default WebDirectCustomHomeUrlsCard;
