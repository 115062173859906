import CardField from "@/components/CardField";
import useDialogController from "@/hooks/useDialogController.tsx";
import type { SideCarDeployment } from "@/queries/sidecar.ts";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import { Box, Card, CardActionArea, CardContent, CardHeader, Stack } from "@mui/material";
import type { ReactNode } from "react";
import DeploymentDialog from "./DeploymentDialog/index.ts";
import StateCardField from "./StateCardField.tsx";

type Props = {
    deployment: SideCarDeployment;
};

const DeploymentCard = ({ deployment }: Props): ReactNode => {
    const dialogController = useDialogController();

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardActionArea onClick={dialogController.open} sx={{ flexGrow: 1 }}>
                <CardHeader
                    avatar={<CloudCircleIcon />}
                    title={deployment.title}
                    subheader={deployment.product.name}
                    titleTypographyProps={{
                        textOverflow: "ellipsis",
                        noWrap: true,
                    }}
                    subheaderTypographyProps={{
                        textOverflow: "ellipsis",
                        noWrap: true,
                    }}
                    sx={{
                        ".MuiCardHeader-content": {
                            minWidth: 0,
                        },
                    }}
                />
                <Box
                    sx={{
                        height: "100%",
                    }}
                >
                    <CardContent>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            sx={{
                                rowGap: 2,
                                columnGap: 2,
                            }}
                        >
                            <StateCardField
                                state={deployment.state}
                                sx={{ minWidth: 120, width: 120, maxWidth: 120 }}
                            />
                            {deployment.metadata?.version && (
                                <CardField label="Version" value={deployment.metadata.version} />
                            )}
                        </Stack>
                    </CardContent>
                </Box>
            </CardActionArea>

            {dialogController.mount && (
                <DeploymentDialog
                    dialogProps={dialogController.dialogProps}
                    deployment={deployment}
                />
            )}
        </Card>
    );
};

export default DeploymentCard;
