import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import type { SnapBack } from "@/queries/snapback.ts";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import { Chip, ListItem, ListItemText, Stack, useMediaQuery, useTheme } from "@mui/material";
import humanFormat from "human-format";
import type { ReactNode } from "react";
import RetainedChip from "./RetainedChip.tsx";
import SnapBackActionButton from "./SnapBackActionButton.tsx";
import StatusChip from "./StatusChip.tsx";

type Props = {
    snapBack: SnapBack;
    hasOnlineSnapBack: boolean;
    hasPendingSnapBack: boolean;
};

const SnapBackListItem = ({
    snapBack,
    hasOnlineSnapBack,
    hasPendingSnapBack,
}: Props): ReactNode => {
    const { zoneId } = useTimeZoneContext();
    const theme = useTheme();
    const compactView = useMediaQuery(theme.breakpoints.down("lg"));

    const propertyChips = (
        <Stack
            direction="row"
            sx={{
                gap: 1,
                flexWrap: "wrap",
            }}
        >
            <StatusChip status={snapBack.status} size="small" />
            <RetainedChip retained={snapBack.retained} size="small" />
            <Chip
                label={humanFormat(snapBack.volumeSize * 1000 * 1000 * 1000, { unit: "B" })}
                size="small"
            />
            {snapBack.preserved && <Chip label="Preserved" size="small" color="info" />}
        </Stack>
    );

    return (
        <ListItem
            secondaryAction={
                <SnapBackActionButton
                    snapBack={snapBack}
                    hasOnlineSnapBack={hasOnlineSnapBack}
                    hasPendingSnapBack={hasPendingSnapBack}
                    edge="end"
                />
            }
        >
            <ListItemText
                primary={snapBack.description}
                secondary={
                    <Stack
                        sx={{
                            rowGap: 0.5,
                        }}
                    >
                        {snapBack.createdAt
                            .withZoneSameInstant(zoneId)
                            .format(zonedDateTimeFormatter)}
                        {compactView && propertyChips}
                    </Stack>
                }
                primaryTypographyProps={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                }}
                secondaryTypographyProps={{ component: "div" }}
                sx={{
                    minWidth: !compactView ? 330 : undefined,
                    maxWidth: !compactView ? 330 : undefined,
                    width: !compactView ? 330 : undefined,
                    mr: !compactView ? 2 : undefined,
                }}
            />
            {!compactView && (
                <ListItemText
                    secondary={propertyChips}
                    secondaryTypographyProps={{ component: "div" }}
                />
            )}
        </ListItem>
    );
};

export default SnapBackListItem;
