import isValidHostname from "is-valid-hostname";
import { match } from "ts-pattern";
import { type SomeZodObject, type ZodType, type ZodTypeAny, z } from "zod";
import type {
    ConfigObjectField,
    ConfigValueField,
    OutputsObjectField,
    OutputsValueField,
    SideCarManifest,
} from "./manifest.ts";

const generateValueFieldSchema = (definition: ConfigValueField | OutputsValueField): ZodTypeAny =>
    match(definition.type)
        .with("string", (): ZodType<string> => {
            let schema = z.string();

            if ("disableTrim" in definition && !definition.disableTrim) {
                schema = schema.trim();
            }

            if (definition.pattern) {
                schema = schema.regex(
                    new RegExp(definition.pattern),
                    definition.errorMessages?.pattern,
                );
            }

            if (definition.minLength) {
                schema = schema.min(definition.minLength);
            }

            if (definition.maxLength) {
                schema = schema.max(definition.maxLength);
            }

            if (!definition.format) {
                return schema;
            }

            return match(definition.format)
                .with("email", () => schema.email())
                .with("uri", () => schema.url())
                .with("hostname", () => schema.refine(isValidHostname, "Invalid hostname"))
                .exhaustive();
        })
        .with("boolean", () => z.boolean())
        .exhaustive();

const makeOptional = (schema: ZodTypeAny): ZodTypeAny => {
    return z.preprocess((value) => (value === "" ? undefined : value), schema.optional());
};

const generateObjectFieldSchema = (
    definition: ConfigObjectField | OutputsObjectField,
): SomeZodObject =>
    z.object(
        Object.fromEntries(
            Object.entries(definition.properties).map(([key, value]) => {
                const required = definition.required?.includes(key) ?? false;
                let schema = generateValueFieldSchema(value);

                if (!required) {
                    schema = makeOptional(schema);
                }

                return [key, schema];
            }),
        ),
    );

export const generateRootSchema = (
    definition: SideCarManifest["config"] | SideCarManifest["outputs"],
) =>
    z.object(
        Object.fromEntries(
            Object.entries(definition.properties).map(([key, value]) => {
                const required = definition.required?.includes(key) ?? false;
                let schema = match(value)
                    .with({ type: "object" }, (value) => generateObjectFieldSchema(value))
                    .otherwise((value) => generateValueFieldSchema(value));

                if (!required) {
                    schema = makeOptional(schema);
                }

                return [key, schema];
            }),
        ),
    );
