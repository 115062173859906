import Layout, { BodyContainer } from "@/components/Layout";
import NotFoundCard from "@/components/NotFoundCard";
import { FullPageSpinner } from "@/components/Spinner";
import { useQueryOptionsFactory } from "@/queries";
import type { Client } from "@/queries/client.ts";
import { activeClientIdKey } from "@/routes/index.tsx";
import { useSuspenseQuery } from "@tanstack/react-query";
import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { JsonApiError } from "jsonapi-zod-query";
import { enqueueSnackbar } from "notistack";
import { type ReactNode, createContext, useContext } from "react";

const ActiveClientContext = createContext<Client | null>(null);

const Root = (): ReactNode => {
    const qof = useQueryOptionsFactory();
    const { data: activeClient } = useSuspenseQuery(qof.client.get(Route.useParams().clientId));
    const {
        data: {
            data: assignedClients,
            meta: { selfAssignAllowed },
        },
    } = useSuspenseQuery(qof.client.list());

    return (
        <ActiveClientContext.Provider value={activeClient}>
            <Layout
                activeClient={activeClient}
                assignedClients={assignedClients}
                selfAssignAllowed={selfAssignAllowed}
            >
                <Outlet />
            </Layout>
        </ActiveClientContext.Provider>
    );
};

export const useActiveClient = (): Client => {
    const client = useContext(ActiveClientContext);

    if (!client) {
        throw new Error("useActiveClient used outside ActiveClientProvider");
    }

    return client;
};

export const Route = createFileRoute("/$clientId")({
    component: Root,
    pendingComponent: FullPageSpinner,
    notFoundComponent: () => (
        <BodyContainer>
            <NotFoundCard />
        </BodyContainer>
    ),
    beforeLoad: async ({ params, context }) => {
        if (params.clientId === "clients") {
            enqueueSnackbar(
                "We detected that you have bookmarked a legacy URL. Please update your bookmarks.",
                { variant: "warning" },
            );
            throw redirect({ to: "/" });
        }

        let client: Client;

        try {
            client = await context.queryClient.ensureQueryData(
                context.qof.client.get(params.clientId),
            );
        } catch (error) {
            if (error instanceof JsonApiError && error.status === 404) {
                throw redirect({ to: "/" });
            }

            throw error;
        }

        window.localStorage.setItem(activeClientIdKey, client.id);
    },
});
