import useDialogController from "@/hooks/useDialogController.tsx";
import type { SideCarEnvironment, SideCarProduct } from "@/queries/sidecar.ts";
import AddDeploymentDialog from "@/routes/$clientId/sidecar/-components/AddDeploymentDialog.tsx";
import HelpIcon from "@mui/icons-material/Help";
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Chip,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import type { ReactNode } from "react";
import Markdown from "react-markdown";

type Props = {
    product: SideCarProduct;
    environments: SideCarEnvironment[];
};

const ProductCard = ({ product, environments }: Props): ReactNode => {
    const dialogController = useDialogController();

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardActionArea
                onClick={dialogController.open}
                disabled={product.quota.used >= product.quota.limit}
                sx={{
                    flexGrow: 1,
                    "&:hover:has(a:hover) .MuiCardActionArea-focusHighlight": {
                        opacity: 0,
                    },
                }}
            >
                <CardHeader
                    title={product.name}
                    subheader={`${product.quota.used} out of ${product.quota.limit} installed`}
                    action={
                        product.documentationUrl ? (
                            <IconButton
                                component="a"
                                href={product.documentationUrl}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                sx={{ pointerEvents: "all" }}
                            >
                                <HelpIcon />
                            </IconButton>
                        ) : undefined
                    }
                />
                <Box
                    sx={{
                        height: "100%",
                    }}
                >
                    <CardContent>
                        {product.tags.length > 0 && (
                            <Stack spacing={1} direction="row" sx={{ flexWrap: "wrap", mb: 2 }}>
                                {product.tags.map((tag) => (
                                    <Chip key={tag} size="small" label={tag} />
                                ))}
                            </Stack>
                        )}

                        <Markdown
                            skipHtml
                            allowedElements={["p"]}
                            unwrapDisallowed
                            components={{
                                p: ({ children }) => (
                                    <Typography
                                        variant="inherit"
                                        sx={{ mb: 2, "&:last-child": { mb: 0 } }}
                                    >
                                        {children}
                                    </Typography>
                                ),
                            }}
                        >
                            {product.description}
                        </Markdown>
                    </CardContent>
                </Box>
            </CardActionArea>

            {dialogController.mount && (
                <AddDeploymentDialog
                    dialogProps={dialogController.dialogProps}
                    product={product}
                    environments={environments}
                />
            )}
        </Card>
    );
};

export default ProductCard;
