import { useTimeZoneContext } from "@/components/TimeZoneProvider";
import type { HostEvent } from "@/queries/host-event.ts";
import { zonedDateTimeFormatter } from "@/utils/format.ts";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
    Chip,
    Divider,
    List,
    ListItem,
    ListItemText,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Fragment, type ReactNode, useMemo } from "react";
import ReoccurrenceChip from "./ReoccurrenceChip.tsx";
import StatusChip from "./StatusChip.tsx";

type Props = {
    events: HostEvent[];
};

const EventList = ({ events }: Props): ReactNode => {
    const { zoneId } = useTimeZoneContext();
    const theme = useTheme();
    const compactView = useMediaQuery(theme.breakpoints.down("md"));

    const sortedEvents = useMemo(() => {
        return [...events].sort((a, b) => b.runsAt.compareTo(a.runsAt));
    }, [events]);

    return (
        <List disablePadding>
            {sortedEvents.map((event, index) => (
                <Fragment key={event.id}>
                    {index > 0 && <Divider />}
                    <ListItem key={event.id}>
                        <ListItemText
                            primary={event.description}
                            secondary={
                                <>
                                    {compactView && (
                                        <div>
                                            {event.runsAt
                                                .withZoneSameInstant(zoneId)
                                                .format(zonedDateTimeFormatter)}
                                        </div>
                                    )}

                                    <Stack
                                        spacing={1}
                                        direction="row"
                                        sx={{
                                            mt: 0.5,
                                        }}
                                    >
                                        <StatusChip size="small" status={event.status} />
                                        <ReoccurrenceChip
                                            size="small"
                                            reoccurrence={event.reoccurrence}
                                        />
                                        {event.requiresReboot && (
                                            <Chip
                                                size="small"
                                                icon={<RestartAltIcon />}
                                                color="warning"
                                                label="Requires reboot"
                                            />
                                        )}
                                    </Stack>
                                </>
                            }
                            secondaryTypographyProps={{
                                component: "div",
                            }}
                        />
                        {!compactView && (
                            <ListItemText
                                primary={event.runsAt
                                    .withZoneSameInstant(zoneId)
                                    .format(zonedDateTimeFormatter)}
                                primaryTypographyProps={{
                                    textAlign: "right",
                                }}
                            />
                        )}
                    </ListItem>
                </Fragment>
            ))}
        </List>
    );
};

export default EventList;
