import type { AuthenticatedFetch } from "@/hooks/useAuthenticatedFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { queryOptions } from "@tanstack/react-query";
import { createDataSelector, createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const actionStateSchema = z.enum(["allowed", "pending", "disallowed"]);

const attributesSchema = z.object({
    hostId: z.string().min(1),
    version: z.string().min(1),
    license: z.object({
        code: z.string().min(1),
        expiresOn: zj.localDate(),
        url: z.string().url(),
    }),
    upgrade: z
        .object({
            approvedVersion: z.string().min(1),
            plannedOn: zj.localDate().nullable(),
            message: z.string().min(1),
        })
        .nullable(),
    adminConsoleUrl: z.string().url(),
    webDirectCustomHomeUrls: z.array(z.string().url()),
    hostedFtpAccessEnabled: z.boolean(),
    actions: z.object({
        restartScriptingEngine: actionStateSchema,
        restartWebPublishingEngine: actionStateSchema,
        restartDataApi: actionStateSchema,
        restartODataApi: actionStateSchema,
        restartOttoFms: actionStateSchema,
        resetAdminPassword: actionStateSchema,
        toggleHostedFtpAccess: actionStateSchema,
    }),
});

const fileMakerServerSelector = createDataSelector(
    createResourceSelector({
        type: "filemaker_server",
        attributesSchema,
    }),
);

export type FileMakerServer = ReturnType<typeof fileMakerServerSelector>;

export const createFileMakerServerQueryOptionsFactory = (authFetch: AuthenticatedFetch) => ({
    get: (clientId: string, hostId: string) =>
        queryOptions({
            queryKey: ["client", clientId, "host", hostId, "filemaker-server"],
            queryFn: async ({ signal }) => {
                const url = apiUrl(`/clients/${clientId}/hosts/${hostId}/filemaker-server`);
                const response = await authFetch(url, { signal });
                await handleJsonApiError(response);
                return fileMakerServerSelector(await response.json());
            },
        }),
});
