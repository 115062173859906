import type { SideCarEnvironment } from "@/queries/sidecar.ts";
import { type SideCarManifest, getConfigSchema } from "@/sidecar/manifest.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { ListSubheader, Stack, autocompleteClasses, styled } from "@mui/material";
import memoTheme from "@mui/material/utils/memoTheme";
import { RhfAutocomplete, RhfTextField } from "mui-rhf-integration";
import { type ReactNode, useMemo } from "react";
import { useForm } from "react-hook-form";
import invariant from "tiny-invariant";
import { z } from "zod";
import { createDisplayGroups } from "../../-utils/layout.ts";
import ConfigDisplayGroup from "./ConfigDisplayGroup.tsx";
import ConfigValueField from "./ConfigValueField.tsx";

const environmentSchema = z.object({
    account: z.object({
        id: z.string(),
        name: z.string(),
    }),
    region: z.object({
        id: z.string(),
        name: z.string(),
    }),
});

type Environment = z.output<typeof environmentSchema>;

const AutocompleteGroupLabel = styled(ListSubheader, {
    name: "MuiAutocomplete",
    slot: "GroupLabel",
    overridesResolver: (_props, styles) => styles.groupLabel,
})(
    memoTheme(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        top: -8,
    })),
);

const AutocompleteGroupUl = styled("ul", {
    name: "MuiAutocomplete",
    slot: "GroupUl",
    overridesResolver: (_props, styles) => styles.groupUl,
})({
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
        paddingLeft: 24,
    },
});

export type ConfigFormValues<TSelectEnvironment extends boolean> = {
    title: string;
    config: Record<string, unknown>;
    environment: TSelectEnvironment extends true ? Environment : never;
};

type Props<TSelectEnvironment extends boolean> = {
    manifest: SideCarManifest;
    onSubmit: (values: ConfigFormValues<NoInfer<TSelectEnvironment>>) => void | Promise<void>;
    environments?: TSelectEnvironment extends true ? SideCarEnvironment[] : never;
    defaultValues?: {
        title: string;
        config: Record<string, unknown>;
    };
    outputs?: Record<string, unknown>;
    disabled?: boolean;
    wrapper?: (children: ReactNode) => ReactNode;
};

const ConfigForm = <TSelectEnvironment extends boolean>({
    manifest,
    onSubmit,
    environments,
    defaultValues,
    outputs,
    wrapper = (children) => children,
    disabled,
}: Props<TSelectEnvironment>): ReactNode => {
    const configSchema = useMemo(() => getConfigSchema(manifest), [manifest]);
    const schema = useMemo(() => {
        let schema = z.object({
            title: z.string().trim().min(1),
            config: configSchema.default({}),
        });

        if (environments) {
            schema = schema.extend({ environment: environmentSchema });
        }

        return schema;
    }, [configSchema, environments]);
    const form = useForm<Record<string, unknown>, unknown, ConfigFormValues<TSelectEnvironment>>({
        resolver: zodResolver(schema),
        defaultValues,
    });
    const [displayGroups, rootProperties] = useMemo(
        () => createDisplayGroups(manifest, "config"),
        [manifest],
    );

    const environmentSelect = useMemo(() => {
        if (!environments) {
            return null;
        }

        const options = environments.reduce((options, environment) => {
            for (const region of environment.regions) {
                options.push({
                    account: environment.account,
                    region,
                });
            }

            return options;
        }, [] as Environment[]);

        return (
            <RhfAutocomplete
                options={options}
                control={form.control}
                name="environment"
                freeSolo={false}
                textFieldProps={{
                    label: "Environment",
                    required: true,
                }}
                disableClearable
                groupBy={(option) => option.account.id}
                getOptionLabel={(option) => `${option.account.name}: ${option.region.name}`}
                renderGroup={(params) => {
                    const environment = environments.find(
                        (environment) => environment.account.id === params.group,
                    );
                    invariant(environment);

                    return (
                        <li key={params.key}>
                            <AutocompleteGroupLabel>
                                {environment.account.name}
                            </AutocompleteGroupLabel>
                            <AutocompleteGroupUl>{params.children}</AutocompleteGroupUl>
                        </li>
                    );
                }}
                renderOption={(props, option) => <li {...props}>{option.region.name}</li>}
            />
        );
    }, [form.control, environments]);

    return (
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
            {wrapper(
                <Stack spacing={2}>
                    <RhfTextField
                        control={form.control}
                        name="title"
                        label="Title"
                        required
                        disabled={disabled}
                    />
                    {environmentSelect}

                    {rootProperties.map((property) => (
                        <ConfigValueField
                            key={property.key}
                            property={property}
                            outputs={outputs}
                            control={form.control}
                            disabled={disabled}
                        />
                    ))}

                    {displayGroups.map((displayGroup) => (
                        <ConfigDisplayGroup
                            key={displayGroup.key}
                            control={form.control}
                            displayGroup={displayGroup}
                            outputs={outputs}
                            disabled={disabled}
                        />
                    ))}
                </Stack>,
            )}
        </form>
    );
};

export default ConfigForm;
