import ConfirmDialog from "@/components/ConfirmDialog";
import {
    type StateChangeValues,
    useRestartHostMutation,
    useStartHostMutation,
    useStopHostMutation,
} from "@/mutations/host.ts";
import type { Host } from "@/queries/host.ts";
import { useActiveClient } from "@/routes/$clientId.tsx";
import { getErrorMessage } from "@/utils/api.ts";
import { LoadingButton } from "@mui/lab";
import { ButtonGroup, type SxProps } from "@mui/material";
import type { UseMutationResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useConfirm } from "react-confirm-hook";

type Props = {
    host: Host;
    sx?: SxProps;
};

const StateActions = ({ host, sx }: Props): ReactNode => {
    const { id: clientId } = useActiveClient();
    const confirm = useConfirm(ConfirmDialog);
    const startMutation = useStartHostMutation(clientId);
    const stopMutation = useStopHostMutation(clientId);
    const restartMutation = useRestartHostMutation(clientId);
    const { enqueueSnackbar } = useSnackbar();
    const disabled = startMutation.isPending || stopMutation.isPending || restartMutation.isPending;

    const runStateChange = (mutation: UseMutationResult<string, Error, StateChangeValues>) => {
        mutation.mutate(
            { hostId: host.id },
            {
                onSuccess: (message) => {
                    enqueueSnackbar(message, { variant: "success" });
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <ButtonGroup sx={sx}>
            <LoadingButton
                disabled={disabled || !host.state.actions.has("start")}
                loading={startMutation.isPending}
                onClick={() => {
                    confirm({
                        title: "Confirm start",
                        message: "Are you sure that you want to start this host?",
                        onConfirm: () => {
                            runStateChange(startMutation);
                        },
                    });
                }}
            >
                Start host
            </LoadingButton>
            <LoadingButton
                disabled={disabled || !host.state.actions.has("stop")}
                loading={stopMutation.isPending}
                onClick={() => {
                    confirm({
                        title: "Confirm stop",
                        message: "Are you sure that you want to stop this host?",
                        onConfirm: () => {
                            runStateChange(stopMutation);
                        },
                    });
                }}
            >
                Stop host
            </LoadingButton>
            <LoadingButton
                disabled={disabled || !host.state.actions.has("restart")}
                loading={restartMutation.isPending}
                onClick={() => {
                    confirm({
                        title: "Confirm restart",
                        message: "Are you sure that you want to restart this host?",
                        onConfirm: () => {
                            runStateChange(restartMutation);
                        },
                    });
                }}
            >
                Restart host
            </LoadingButton>
        </ButtonGroup>
    );
};

export default StateActions;
